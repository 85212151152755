<template>
  <div class="leaderboard-style">
    <titles :title="$t('text.t431')" />
    <div class="box1 between-center">
      <div class="chose center-center" @click="show=true">
        <div class="t1">{{activeName}}</div>
        <van-icon name="arrow-down" size="16" />
      </div>
      <!-- <div class="chose center-center" @click="timeShow=true">
        <div class="t1">{{activeTimeName}}</div>
        <van-icon name="arrow-down" size="16" />
      </div> -->
    </div>
    <div class="box2">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('text.t39')"
        :offset="10"
        @load="getlist"
        ref="vanList"
      >
        <el-card class="box-card" :class="index+1 == list.length ? '':'mb20'" v-for="(item,index) in list" :key="index">
          <div @click="goAhead(`/set?id=${item.id}&chainType=${item.chain}`)">
            <div class="box3 start-center">
              <div class="b1">{{index + 1}}</div>
              <div class="b2">
                <img :src="item.collectionimage" class="b2-imgs" />
              </div>
              <div class="b3 between-center">
                <div class="t1">{{item.collectionname}}</div>
                <div class="right">
                  <div class="tb1 start-center">
                    <img :src="chain_list.find(ele=>ele.chain_type == item.chain).chain_logo" class="tb1-i1" />
                    <div class="tb1-t1">
                      {{active==0 || active==1 ? item.total_price :
                        active==2 || active==3 ? item.min_price :
                        active==4 || active==5 ? item.average :
                        active==6 || active==7 ? item.holders :
                        active==8 || active==9 ? item.collections : ''}}
                    </div>
                  </div>
                  <div class="text3">
                    {{active==0 || active==1 ? $t('text.t478') :
                      active==2 || active==3 ? $t('text.t295') :
                      active==4 || active==5 ? $t('text.t479') :
                      active==6 || active==7 ? $t('text.t294') :
                      active==8 || active==9 ? $t('text.t480') : ''}}
                  </div>
                </div>
              </div>
            </div>
            <div class="box4">
              <div class="item" v-if="active!=0 || active!=1">
                <div class="t1">{{$t('text.t296')}}</div>
                <div class="center-center">
                  <img :src="chain_list.find(ele=>ele.chain_type == item.chain).chain_logo" class="item-i1" />
                  <div class="item-t1">{{item.total_price}}</div>
                </div>
              </div>
              <div class="item" v-if="active!=2 || active!=3">
                <div class="t1">{{$t('text.t295')}}</div>
                <div class="center-center">
                  <img :src="chain_list.find(ele=>ele.chain_type == item.chain).chain_logo" class="item-i1" />
                  <div class="item-t1">{{item.min_price}}</div>
                </div>
              </div>
              <div class="item" v-if="active!=4 || active!=5">
                <div class="t1">{{$t('text.t479')}}</div>
                <div class="center-center">
                  <img :src="chain_list.find(ele=>ele.chain_type == item.chain).chain_logo" class="item-i1" />
                  <div class="item-t1">{{item.average}}</div>
                </div>
              </div>
              <div class="item" v-if="active!=6 || active!=7">
                <div class="t1">{{$t('text.t294')}}</div>
                <div class="center-center">
                  <div class="item-t1">{{item.holders}}</div>
                </div>
              </div>
              <div class="item" v-if="active!=8 || active!=9">
                <div class="t1">{{$t('text.t293')}}</div>
                <div class="center-center">
                  <div class="item-t1">{{item.collections}}</div>
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </van-list>
    </div>
    <van-popup v-model="show" close-icon-position="top-left" round closeable position="bottom">
      <div class="popchoose">
        <div class="pc1">{{$t('text.t481')}}</div>
        <div class="pc2">
          <div :class="item.line?'lines':''" v-for="item in typeList" :key="item.type">
            <div class="item between-center" :class="active==item.type?'active':''" @click="chooseType(item.type)">
              <div class="left">{{item.text}}</div>
              <van-icon v-if="active==item.type" name="success" size="18" />
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="timeShow" close-icon-position="top-left" round closeable position="bottom">
      <div class="popchoose">
        <div class="pc1">{{$t('text.t482')}}</div>
        <div class="pc2">
          <div :class="item.line?'lines':''" v-for="item in timeList" :key="item.type">
            <div class="item between-center" :class="activeTime==item.type?'active':''" @click="chooseTimeType(item.type)">
              <div class="left">{{item.text}}</div>
              <van-icon v-if="activeTime==item.type" name="success" size="18" />
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import titles from "@/components/titles/index.vue";
import { mapState } from "vuex";
export default {
  components:{
    titles
  },
  data(){
    return{
      show:false,
      timeShow:false,
      active:0,
      activeTime:0,
      list: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 20,
    }
  },
  computed:{
    ...mapState(["chain_list"]),
    activeName(){
      return this.typeList.find(ele => ele.type == this.active).text
    },
    activeTimeName(){
      return this.timeList.find(ele => ele.type == this.activeTime).text
    },
    timeList(){
      return [
        {
          type:0,
          text:this.$t('text.t483')
        },
        {
          type:1,
          text:this.$t('text.t484')
        },
        {
          type:2,
          text:this.$t('text.t485')
        },
        {
          type:3,
          text:this.$t('text.t486')
        },
      ]
    },
    typeList(){
      return [
        {
          type:0,
          text:this.$t('text.t487')
        },
        {
          type:1,
          text:this.$t('text.t488'),
          line:true
        },
        {
          type:2,
          text:this.$t('text.t489')
        },
        {
          type:3,
          text:this.$t('text.t490'),
          line:true
        },
        {
          type:4,
          text:this.$t('text.t491')
        },
        {
          type:5,
          text:this.$t('text.t492'),
          line:true
        },
        {
          type:6,
          text:this.$t('text.t493')
        },
        {
          type:7,
          text:this.$t('text.t494'),
          line:true
        },
        {
          type:8,
          text:this.$t('text.t495')
        },
        {
          type:9,
          text:this.$t('text.t496'),
          line:true
        },
      ]
    }
  },
  methods:{
    getlist() {
      let arg = {
        page: this.page,
        limit: this.limit,
        type:this.active,
        time:this.activeTime
      };
      this.$http.get("/core/ranking", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished = true;
        this.page++;
        this.loading = false;
        this.list = [...this.list, ...data];
      });
    },
    clearList(){
      this.page = 1
      this.limit = 20
      this.loading = false
      this.finished = false
      this.list = []
      this.$refs.vanList.check()
    },
    chooseType(type){
      this.active = type
      this.show = false
      this.clearList()
    },
    chooseTimeType(type){
      this.activeTime = type
      this.timeShow = false
      this.clearList()
    }
  }
}
</script>

<style lang="less">
.leaderboard-style{
  min-height: 100vh;
  background-color: #f1f1f1;
  .box1{
    padding: 20px 25px 10px;
    background-color: #fff;
    .chose{
      padding: 10px 17px;
      background: #FFFFFF;
      border: 1px solid #E1E1E1;
      border-radius: 30px;
      .t1{
        font-size: 12px;
        font-weight: 500;
        color: #323232;
        margin-right: 10px;
      }
    }
  }
  .box2{
    padding: 11px 7px;
    .el-card__body, .el-main{
      padding: 0;
    }
    .box-card{
      width: 100%;
      border-radius: 10px;
      // margin-bottom: 20px;
      border: 1px solid #E1E1E1;
    }
    .mb20{
      margin-bottom: 20px;
    }
    .box3{
      padding: 10px 0 0 15px;
      .b1{
        font-size: 15px;
        font-weight: bold;
        color: #323232;
        margin-right: 13px;
      }
      .b2{
        width: 58px;
        height: 58px;
        border: 1px solid #323232;
        border-radius: 50%;
        padding: 4px;
        box-sizing: border-box;
        .b2-imgs{
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .b3{
        flex: 1;
        border-bottom: 1px solid #E1E1E1;
        padding: 0 14px 8px 10px;
        .t1{
          font-size: 15px;
          font-weight: bold;
          color: #323232;
        }
        .right{
          text-align: right;
          .tb1{
            margin-bottom: 10px;
            .tb1-i1{
              width: 12px;
              height: 12px;
              margin-right: 5px;
            }
            .tb1-t1{
              font-size: 15px;
              font-weight: 500;
              color: #323232;
            }
          }
          .text2{
            font-size: 12px;
            font-weight: 500;
            color: #0B7F13;
            margin-bottom: 5px;
          }
          .text3{
            font-size: 12px;
            font-weight: 500;
            color: #909090;
          }
        }
      }
    }
    .box4{
      padding: 15px 0;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .item{
        text-align: center;
        .t1{
          font-size: 12px;
          font-weight: 500;
          color: #909090;
          margin-bottom: 4px;
        }
        .item-i1{
          width: 12px;
          height: 12px;
          margin-right: 4px;
        }
        .item-t1{
          font-size: 12px;
          font-weight: bold;
          color: #323232;
          word-break:break-all;
        }
      }
    }
  }
  .popchoose{
    .pc1{
      padding: 15px 0;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #323232;
    }
    .pc2{
      .lines{
        border-bottom: 1px solid #E1E1E1;
      }
      .item{
        padding: 15px 20px;
        .left{
          font-size: 12px;
          font-weight: bold;
          color: #323232;
        }
      }
      .active{
        background: #F4F4F4;
      }
    }
  }
}
</style>